
import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import * as util from "@/util";
const _ = require("lodash");

const apiUrl = url => `${window.SAPlatform.server.url}wp-json/superapp/v1${url}`;

Vue.use(Vuex);

const app = {
  namespaced: true,
  state: {
    platform: "",
    user: [],
    public_user: [],
    vendor_profile: [],
    own_vendor_profile: [],
    filter_items: {
      terms: [],
      quickterms: [],
      countries: [],
    },
    filter_choices: {
      distance: 25,
      country: "",
      city: "",
      slugs: [],
      only_open: false,
    },
    feed: [],
    feed_page: 1,
    search_results: [],
    search_active: false,
    search_word: "",
    map_active: false,
    from_gallery: false,
    from_profile: false,
    feed_running: false,
    feed_timestamp: 0,
    abortControllers: [],
  },
  mutations: {
    setPlatform(state, platform) {
      state.platform = platform;
    },
    addUser(state, data) {
      state.user = data;
    },
    addPublicUser(state, data) {
      state.public_user = data;
    },
    addVendorProfile(state, data) {
      state.vendor_profile = data;
    },
    addOwnVendorProfile(state, data) {
      state.own_vendor_profile = data;
    },
    addFilterItems(state, data) {
      state.filter_items = data;
      if (state.filter_choices.country === "") {
        state.filter_choices.country = data.countries[0].country_en;

        if (data.countries[0].cities.length > 0) {
          const defaultCity = data.countries[0].cities.find(city => city.default === true);
          if (defaultCity !== undefined) {
            state.filter_choices.city = defaultCity.city;
          }
        }
      }
    },
    addFilterDistance(state, data) {
      state.filter_choices.distance = data;
    },
    addFilterCountry(state, data) {
      state.filter_choices.country = data;
    },
    addFilterCity(state, data) {
      state.filter_choices.city = data;
    },
    addFilterSlugs(state, data) {
      state.filter_choices.slugs = data;
    },
    addFilterOnlyOpen(state, data) {
      state.filter_choices.only_open = data;
    },
    addFilterPage(state, data) {
      state.filter_choices.page = data;
    },
    addFeed(state, data) {
      state.feed = data;
    },
    updateFeed(state, data) {
      state.feed = _.uniqBy(state.feed.concat(data), "content_id");
    },
    feedRunning(state, running) {
      state.feed_running = running;
    },
    updateFeedPage(state, data) {
      state.feed_page = data;
    },
    handleFeedData(state, [data, page]) {
      const currentTimestamp = state.feed_timestamp;

      if (currentTimestamp == data.feed_timestamp) {
        if (page > 1) {
          this.commit("app/updateFeed", data.posts);
          this.commit("app/updateFeedPage", data.page);
        } else {
          this.commit("app/addFeed", data.posts);
          this.commit("app/updateFeedPage", data.page);
        }
      }
    },
    addSearchResults(state, data) {
      state.search_results = data;
    },
    updateSearchResults(state, data) {
      state.search_results = _.uniqBy(state.search_results.concat(data), "vendor_id");
    },
    setSearchActive(state, data) {
      state.search_active = data;
    },
    setSearchWord(state, data) {
      state.search_word = data;
    },
    setMapActive(state, data) {
      state.map_active = data;
    },
    resetUser(state) {
      state.user = [];
    },
    resetPublicUser(state) {
      state.public_user = [];
    },
    resetVendorProfile(state) {
      state.vendor_profile = [];
    },
    resetOwnVendorProfile(state) {
      state.own_vendor_profile = [];
    },
    resetFilterChoices(state) {
      state.filter_choices = {
        distance: 25,
        country: "",
        city: "",
        slugs: [],
        only_open: false,
      };

      state.filter_choices.country = state.filter_items.countries[0].country_en;
    },
    resetSearchResults(state) {
      state.search_results = [];
    },
    addFromGallery(state, from) {
      state.from_gallery = from;
    },
    addFromProfile(state, from) {
      state.from_profile = from;
    },
    addFeedTimestamp(state, timestamp) {
      state.feed_timestamp = timestamp;
    },
    clearAbortControllers(state) {
      if (state.abortControllers.length > 0) {
        state.abortControllers.forEach(controller => controller.abort());
        state.abortControllers = [];
      }
    },
  },
  actions: {
    async getUser(context) {
      const url = apiUrl("/user");
      return await axios.get(url).then(response => {
        context.commit("addUser", response.data);
      });
    },
    async getPublicUser(context, data) {
      const url = apiUrl("/user/public");
      const params = {
        user_id: data.user_id,
        position: data.position,
      };
      return await axios.get(url, {params}).then(response => {
        context.commit("addPublicUser", response.data);
      });
    },
    async saveUserMeta(context, [type, gender, age]) {
      const url = apiUrl("/user/savemeta");
      const payload = {
        type,
        gender,
        age,
      };
      return await axios.post(url, payload).then(response => response.data);
    },
    async getVendorProfileFromSlug(context, slug) {
      const url = apiUrl("/vendor/profile");
      const params = {
        slug,
        lang: window.SAPlatform.language.current,
      };

      const resp = await axios.get(url, {params});
      context.commit("addVendorProfile", resp.data);
      return resp.data;
    },
    async getVendorProfile(context, vendorId) {
      const url = apiUrl("/vendor/profile");
      const params = {
        vendor_id: vendorId,
        lang: window.SAPlatform.language.current,
      };
      return await axios.get(url, {params}).then(response => {
        context.commit("addVendorProfile", response.data);
        return response.data;
      });
    },
    async getOwnVendorProfile(context, vendorId) {
      const url = apiUrl("/vendor/profile");
      const params = {
        vendor_id: vendorId,
        lang: window.SAPlatform.language.current,
      };
      return await axios.get(url, {params}).then(response => {
        context.commit("addOwnVendorProfile", response.data);
      });
    },
    async getFilterItems(context) {
      const url = apiUrl("/filter/items");
      const lang = window.SAPlatform.language.current;
      return await axios.get(url, {params: {lang}}).then(response => {
        context.commit("addFilterItems", response.data);
      });
    },
    async getFeed(context, data) {
      const page = data.page;
      const position = data.position;
      const currentTimestamp = context.state.feed_timestamp;

      const url = apiUrl("/feed");
      const params = {
        distance: context.state.filter_choices.distance,
        position,
        country: context.state.filter_choices.country,
        city: context.state.filter_choices.city,
        slugs: context.state.filter_choices.slugs.join(","),
        only_open: context.state.filter_choices.only_open,
        feed_timestamp: currentTimestamp,
        page,
      };

      console.log("current timestamp", currentTimestamp);

      return await axios.get(url, {params}).then(response => {
        context.commit("handleFeedData", [response.data, page]);
        return data;
      });
    },

    async searchVendors(context, {value, page, position, signal}) {
      const source = axios.CancelToken.source(); // Create a cancel token source
      signal.addEventListener("abort", () => {
        source.cancel("Search aborted"); // Cancel the request when the signal is aborted
      });

      const url = apiUrl("/search");
      const params = {
        search: value,
        position,
        page,
      };

      try {
        const response = await axios.get(url, {
          params,
          cancelToken: source.token, // Set the cancel token
        });

        context.commit("addSearchResults", response.data);

        return response.data;
      } catch (error) {
        if (axios.isCancel(error)) {
          // Request was canceled, handle accordingly if needed
        } else {
          // Handle other errors
          throw error;
        }
      }
    },

    async searchMoreVendors(context, {value, page, position}) {
      const url = apiUrl("/search");
      const params = {
        search: value,
        position,
        page,
      };

      return await axios.get(url, {params}).then(response => {
        context.commit("updateSearchResults", response.data);
        return response.data;
      });
    },
    async saveTempProfilePicture(context, data) {
      const url = apiUrl("/profile-picture/temp/save");
      const payload = {
        image: data.image,
        filename: data.filename,
      };

      return await axios.post(url, payload).then(response => response.data);
    },
    setFilterDistance(context, data) {
      context.commit("addFilterDistance", data);
    },
    setFilterCountry(context, data) {
      context.commit("addFilterCountry", data);
    },
    setFilterCity(context, data) {
      context.commit("addFilterCity", data);
    },
    setFilterSlugs(context, data) {
      context.commit("addFilterSlugs", data);
    },
    setFilterOnlyOpen(context, data) {
      context.commit("addFilterOnlyOpen", data);
    },
    setFilterPage(context, data) {
      context.commit("addFilterPage", data);
    },
    resetUser(context) {
      context.commit("resetUser");
    },
    resetPublicUser(context) {
      context.commit("resetPublicUser");
    },
    resetVendorProfile(context) {
      context.commit("resetVendorProfile");
    },
    resetOwnVendorProfile(context) {
      context.commit("resetOwnVendorProfile");
    },
    resetFilterChoices(context) {
      context.commit("resetFilterChoices");
    },
    resetSearchResults(context) {
      context.commit("resetSearchResults", []);
    },
  },
  getters: {
    isVendorUser: state => Boolean(state.own_vendor_profile && state.own_vendor_profile.vendor_id),

    shouldAutoplayVideo: (state, getters) => {
      if (!getters.isVendorUser) {
        return true;
      }

      return state.platform !== "ios";
    },
  },
};

const activity = {
  namespaced: true,
  state: {follows: [], pins: []},
  mutations: {
    addActivity(state, data) {
      state.follows = data.follows;
      state.pins = data.pins;
    },
    resetActivity(state) {
      state.follows = [];
      state.pins = [];
    },
  },
  actions: {
    async getUserActivity(context, position) {
      const url = apiUrl("/activity/user");
      const params = {
        position,
      };
      return await axios.get(url, {params}).then(response => {
        context.commit("addActivity", response.data);
      });
    },
    resetActivity(context) {
      context.commit("resetActivity");
    },
  },
  getters: {},
};

const notifications = {
  namespaced: true,
  state: {vendor: [], user: []},
  mutations: {
    addVendorNotifications(state, data) {
      state.vendor = data;
    },
    addUserNotifications(state, data) {
      state.user = data;
    },
    resetVendor(state) {
      state.vendor = [];
    },
    resetUser(state) {
      state.user = [];
    },
  },
  actions: {
    async getVendorNotifications(context, vendorId) {
      const url = apiUrl("/vendor/notifications");
      const params = {
        vendor_id: vendorId,
      };
      return await axios.get(url, {params}).then(response => {
        context.commit("addVendorNotifications", response.data);
      });
    },
    async getUserNotifications(context) {
      const url = apiUrl("/notifications");
      return await axios.get(url).then(response => {
        context.commit("addUserNotifications", response.data);
      });
    },
    resetVendor(context) {
      context.commit("resetVendor");
    },
    resetUser(context) {
      context.commit("resetUser");
    },
  },
  getters: {},
};

const reviews = {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    async sendReview(context, data) {
      const url = apiUrl("/reviews/send");
      const payload = {
        vendor_id: data.vendorId,
        user_id: data.userId,
        parent_id: data.parentId,
        message: data.message,
      };
      return await axios.post(url, payload).then(response => response.data);
    },
  },
  getters: {},
};

const rewards = {
  namespaced: true,
  state: {current_user: [], customer_user: []},
  mutations: {
    addCurrentUserRewards(state, data) {
      state.current_user = data;
    },
    addCustomerUserRewards(state, data) {
      state.customer_user = data;
    },
    resetCurrentUser(state) {
      state.current_user = [];
    },
    resetCustomerUser(state) {
      state.customer_user = [];
    },
  },
  actions: {
    async getCurrentUserRewards(context) {
      const url = apiUrl("/rewards/user");
      return await axios.get(url).then(response => {
        context.commit("addCurrentUserRewards", response.data);
      });
    },
    getCustomerUserRewards(context, [vendorId, customerNo, userId = null]) {
      const url = apiUrl("/rewards/user");
      return new Promise((resolve, reject) => {
        const params = {
          vendor_id: vendorId,
          customer_no: customerNo,
          user_id: userId,
        };
        axios.get(url, {params})
          .then(response => {
            context.commit("addCustomerUserRewards", response.data);
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    async updateUserScore(context, [vendorId, userId, recordId, program, score]) {
      const url = apiUrl("/rewards/update-score");
      const payload = {
        vendor_id: vendorId,
        user_id: userId,
        record_id: recordId,
        program,
        score,
      };
      return await axios.post(url, payload).then(response => {
        context.dispatch("getCustomerUserRewards", [vendorId, null, userId]);
      });
    },
    async claimReward(context, [vendorId, userId, program, recordId, rewardText]) {
      const url = apiUrl("/rewards/claim");
      const payload = {
        vendor_id: vendorId,
        user_id: userId,
        program,
        record_id: recordId,
        reward: rewardText,
      };
      return await axios.post(url, payload).then(response => {
        context.dispatch("getCustomerUserRewards", [vendorId, null, userId]);
      });
    },
    async savePrograms(context, payload) {
      const url = apiUrl("/rewards/save-programs");
      return await axios.post(url, payload);
    },
    resetCurrentUser(context) {
      context.commit("resetCurrentUser");
    },
    resetCustomerUser(context) {
      context.commit("resetCustomerUser");
    },
  },
  getters: {},
};

const posts = {
  namespaced: true,
  state: {
    post_edited: false,
    current_post: {
      content_id: "",
      type_id: null,
      type_slug: "",
      title_1: "",
      title_2: "",
      text: "",
      flag_color: "",
      flag_text_row_1: "",
      flag_text_row_2: "",
      image: "",
      image_url: "",
      new_image_filename: "",
      is_template: false,
      video: "",
      video_url: "",
      still_image: "",
      still_image_url: "",
      new_video_filename: "",
      new_still_image_filename: "",
      schedule: false,
      schedule_template: "",
      schedule_start: "",
      schedule_end: "",
      recurrence: false,
      recurrence_start_date: "",
      recurrence_start_time: "",
      recurrence_end_time: "",
      recurrence_days: [],
      weekly_recurrence: false,
      stop_recurrence: "",
      event_type_id: "",
    },
    templates: [
      {
        id: "",
        url: "",
      },
    ],
  },
  mutations: {
    addTemplates(state, data) {
      state.templates = data;
    },
    addCurrentPost(state, data) {
      state.current_post = data;
    },
    updateCurrentPost(state, data) {
      state.post_edited = true;
      state.current_post[data.field] = data.value;
    },
    resetCurrentPost(state) {
      state.post_edited = false;
      state.current_post = {
        content_id: "",
        type_id: null,
        type_slug: "",
        title_1: "",
        title_2: "",
        text: "",
        flag_color: "",
        flag_text_row_1: "",
        flag_text_row_2: "",
        image: "",
        image_url: "",
        new_image_filename: "",
        is_template: false,
        video: "",
        video_url: "",
        still_image: "",
        still_image_url: "",
        new_video_filename: "",
        new_still_image_filename: "",
        schedule: false,
        schedule_template: "",
        schedule_start: "",
        schedule_end: "",
        recurrence: false,
        recurrence_start_date: "",
        recurrence_start_time: "",
        recurrence_end_time: "",
        recurrence_days: [],
        weekly_recurrence: false,
        stop_recurrence: "",
        event_type_id: "",
      };
    },
  },
  actions: {
    async getTemplates(context) {
      const url = apiUrl("/templates");
      return await axios.get(url).then(response => {
        context.commit("addTemplates", response.data);
      });
    },
    setCurrentPost(context, id) {
      const post = _.cloneDeep(context.rootState.app.own_vendor_profile.content.find(content => content.content_id === id));
      context.commit("addCurrentPost", post);
    },
    updateCurrentPost(context, data) {
      context.commit("updateCurrentPost", data);
    },
    async savePost(context) {
      const url = apiUrl("/vendor/content/save");
      const payload = Object.assign({}, context.state.current_post);
      payload.vendor_id = context.rootState.app.own_vendor_profile.vendor_id;
      return await axios.post(url, payload).then(response => {});
    },
    async saveTempImage(context, data) {
      const event = new CustomEvent("loading-indicator", {detail: {show: true, action: "saveTempImage"}});
      window.dispatchEvent(event);

      const url = apiUrl("/vendor/content/temp/image");
      const payload = {
        image: data.image,
        filename: data.filename,
      };

      return await axios.post(url, payload).then(response => {
        context.commit("updateCurrentPost", {field: "new_image_filename", value: response.data.filename});

        const event = new CustomEvent("loading-indicator", {detail: {show: false, action: "saveTempImage"}});
        window.dispatchEvent(event);

        return response.data;
      });
    },
    async saveTempVideo(context, data) {
      let event = new CustomEvent("loading-indicator", {detail: {show: true, action: "saveTempVideo"}});
      window.dispatchEvent(event);

      const url = apiUrl("/vendor/content/temp/video2");
      const resp = await axios.post(url, data.video, {
        params: {
          filename: data.fileName,
        },
        headers: {
          "Content-Type": data.video.type,
        },
      });

      console.log("Uploaded post video", resp.data);

      context.commit("updateCurrentPost", {field: "new_video_filename", value: resp.data.filename});
      event = new CustomEvent("loading-indicator", {detail: {show: false, action: "saveTempVideo"}});
      window.dispatchEvent(event);

      return resp.data;
    },
    async saveTempVideoStill(context, data) {
      const event = new CustomEvent("loading-indicator", {detail: {show: true, action: "saveTempVideoStill"}});
      window.dispatchEvent(event);

      const url = apiUrl("/vendor/content/temp/image");
      const payload = {
        image: data.image,
        filename: context.state.current_post.new_still_image_filename,
      };

      return await axios.post(url, payload).then(response => {
        context.commit("updateCurrentPost", {field: "new_still_image_filename", value: response.data.filename});

        const event = new CustomEvent("loading-indicator", {detail: {show: false, action: "saveTempVideoStill"}});
        window.dispatchEvent(event);

        return response.data;
      });
    },
    async getDataUrl(context, data) {
      const url = apiUrl("/generate-data-url");
      const params = {
        image_id: data.image_id,
      };

      return await axios.get(url, {params}).then(response => response.data);
    },
    resetCurrentPost(context) {
      context.commit("resetCurrentPost");
    },
  },
  getters: {},
};

const analytics = {
  namespaced: true,
  state: {
    results: {},
  },
  mutations: {
    addAnalytics(state, data) {
      state.results = data;
    },
  },
  actions: {
    async trackLookingFor(context, [vendorId, filters]) {
      const url = apiUrl("/analytics/tracklookingfor");
      const payload = {
        vendor_id: vendorId,
        filters,
      };
      return await axios.post(url, payload).then(response => response.data);
    },

    async getAnalytics(context, [vendorId, lang]) {
      const url = apiUrl("/analytics/get");
      const params = {
        vendor_id: vendorId,
        language: lang,
      };

      return await axios.get(url, {params}).then(response => {
        context.commit("addAnalytics", response.data);
      });
    },
  },
  getters: {},
};

export const store = new Vuex.Store({
  state: {},
  mutations: {
    initialiseStore(state) {
      if (localStorage.getItem("store")) {
        this.replaceState(
          Object.assign(state, JSON.parse(localStorage.getItem("store")))
        );
      }
    },
  },
  actions: {
    resetAll(context) {
      context.dispatch("app/resetUser", null, {root: true});
      context.dispatch("app/resetPublicUser", null, {root: true});
      context.dispatch("app/resetVendorProfile", null, {root: true});
      context.dispatch("app/resetOwnVendorProfile", null, {root: true});
      context.dispatch("app/resetFilterChoices", null, {root: true});
      context.dispatch("app/resetSearchResults", null, {root: true});
      context.dispatch("activity/resetActivity", null, {root: true});
      context.dispatch("notifications/resetVendor", null, {root: true});
      context.dispatch("notifications/resetUser", null, {root: true});
      context.dispatch("rewards/resetCurrentUser", null, {root: true});
      context.dispatch("rewards/resetCustomerUser", null, {root: true});
    },
  },
  getters: {},
  modules: {
    app,
    activity,
    notifications,
    reviews,
    rewards,
    posts,
    analytics,
  },
});

store.subscribe((mutation, state) => {
  try {
    localStorage.setItem("store", JSON.stringify(state));
  } catch (e) {
    console.log(e);
  }
});
