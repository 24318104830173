import GoogleMapsApiLoader from "google-maps-api-loader";
import anyBase from "any-base";

const apiKey = "AIzaSyAmcPtktTXIW8vL2vdX1hzZVUg8HPN67B4";
let google = {};
let distanceMatrix = {};
let placesService = {};

const urlSafeAlphaBet = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ_-.";

const encodeHexString = anyBase(anyBase.HEX, urlSafeAlphaBet);
const encodeDecimal = anyBase(anyBase.DEC, urlSafeAlphaBet);
const decodeToHex = anyBase(urlSafeAlphaBet, anyBase.HEX);
const decodeToDecimal = anyBase(urlSafeAlphaBet, anyBase.DEC);

const contentIdRegex = /^(\d+)_([a-z0-9]+)\.(\d+)$/;
export const encodeContentId = id => {
  const contentIdMatch = id.match(contentIdRegex);

  if (contentIdMatch === null) {
    throw new Error("Invalid content id");
  }

  return [
    encodeHexString(contentIdMatch[2]),
    encodeDecimal(contentIdMatch[3]),
  ].join(".");
};

export const decodeContentId = (encodedId, vendorId) => {
  const lastInd = encodedId.lastIndexOf(".");
  const id = encodedId.slice(0, lastInd);
  const entropy = encodedId.slice(lastInd + 1);

  let contentId = [
    decodeToHex(id),
    decodeToDecimal(entropy),
  ].join(".");

  if (vendorId) {
    contentId = `${vendorId}_${contentId}`;
  }

  return contentId;
};

const doLoadGoogleMapsApi = async () => {
  if (window.google == undefined) {
    google = await GoogleMapsApiLoader({
      libraries: ["places"],
      apiKey,
    });
    distanceMatrix = new google.maps.DistanceMatrixService();
    placesService = new google.maps.places.PlacesService(document.createElement("div"));
    return google;
  }

  google = window.google;
  distanceMatrix = new google.maps.DistanceMatrixService();
  placesService = new google.maps.places.PlacesService(document.createElement("div"));
  return google;
};

let googleMapLoadingPromise = null;
export const loadGoogleMapsApi = () => {
  if (googleMapLoadingPromise !== null) return googleMapLoadingPromise;

  googleMapLoadingPromise = doLoadGoogleMapsApi();
  return googleMapLoadingPromise;
};

export const getGeolocation = () => new Promise((resolve, reject) => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        resolve(position);
      }, (error) => {
        reject(error);
      },
      {enableHighAccuracy: true}
    );
  } else {
    reject("Geolocation is not supported by this browser.");
  }
});

export const getDistance = (origin, destination) => new Promise((resolve, reject) => {
  distanceMatrix.getDistanceMatrix({
    origins: [origin],
    destinations: [destination],
    travelMode: "DRIVING",
  }, (response, status) => {
    if (status == "OK") {
      const kms = response.rows[0].elements[0].distance.text;
      resolve(`${kms} away`);
    } else {
      reject("Error getting distance");
    }
  }
  );
});

export const getHaversineDistance = (origin, destination) => new Promise((resolve, reject) => {
  const earthRadius = 6371; // Earth's radius in kilometers
  const lat1 = origin.lat;
  const lon1 = origin.lng;
  const lat2 = destination.lat;
  const lon2 = destination.lng;

  const dLat = deg2rad(lat2 - lat1);
  const dLon = deg2rad(lon2 - lon1);

  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  let distance = earthRadius * c; // Distance in kilometers
  distance = Math.round(distance * 100) / 100; // Round to 2 decimal places

  resolve(`${distance} km`);

  function deg2rad(deg) {
    return deg * (Math.PI / 180);
  }
});

export const getPlaceDetails = (request) => new Promise((resolve, reject) => {
  placesService.getDetails(request, (place, status) => {
    if (status == google.maps.places.PlacesServiceStatus.OK) {
      resolve(place);
    } else {
      reject("Error getting place details");
    }
  });
});

export function debounce(fn, ms) {
  let timeout = null;
  return (...args) => {
    const call = () => fn(...args);
    clearTimeout(timeout);
    timeout = setTimeout(call, ms);
  };
}

export function throttle(fn, ms) {
  let blocked = false;
  return (...args) => {
    if (blocked) return;

    fn(...args);
    blocked = true;
    setTimeout(() => blocked = false, ms);
  };
}

export function wait(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const PAGE_ID_PATTERN = /\/(\d+)\//;
export function determinePageTitle(page) {
  const path = page.route?.route?.path;
  const res = PAGE_ID_PATTERN.exec(path);
  if (!res) {
    return page.route?.route.analyticsTitle;
  }

  const id = parseInt(res[1]);

  try {
    const wpPage = window.SAPlatform.stores.SAPlatformStore.getters["content/getPost"](id);
    return wpPage?.translations?.en?.title;
  } catch (e) {
    console.warn(`Failed to determine page title for page with id ${id}`, e);
  }
}

export function getProp(obj, keyPath) {
  const pathComponents = keyPath.split(".");

  let item = obj;
  for (const component of pathComponents) {
    item = item[component];
    if (item === undefined || item === null) {
      return item;
    }
  }

  return item;
}
