import * as util from "./util";

const PAGE_ID_PATTERN = /\/(\d+)\//;

function deviceReady() {
  if (!window.cordova) return Promise.resolve();

  return new Promise(resolve => {
    document.addEventListener("deviceready", resolve, {once: true});
  });
}

function isAvailable() {
  return "FirebasePlugin" in window;
}

function access(obj, path) {
  const parts = path.split(".");

  let cur = obj;
  for (const part of parts) {
    cur = cur[part];
    if (cur === undefined) return;
  }

  return cur;
}

export async function logEvent(event, data = {}) {
  await deviceReady();
  if (!isAvailable()) return;

  window.FirebasePlugin.logEvent(event, data);
}

export async function setScreenName(name) {
  console.info(`Screen Name: ${name}`);
  await deviceReady();
  if (!isAvailable()) return;

  window.FirebasePlugin.setScreenName(name);
}

export async function logScreen(page) {
  const title = util.determinePageTitle(page);
  if (title) await setScreenName(title);
}
