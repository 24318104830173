import "framework7-icons";
import "material-design-icons/iconfont/material-icons.css";
import "framework7/css/framework7.bundle.css";
import {SASession} from "@sa-platform/sa-p-core";
import SAPlatform from "@sa-platform/sa-p-core/core.js";
import Framework7 from "framework7/framework7.esm.bundle.js";
import Framework7Vue from "framework7-vue/framework7-vue.esm.bundle.js";
import App from "./app";
import Vue from "vue";
import Vuex from "vuex";
import AppStyles from "./css/app.scss";
import Area51 from "./templates/area51.vue";
import Area52 from "./templates/area52.vue";
import Page from "@sa-platform/sa-t-page/sa-t-page.vue";
import SessionEvents from "@sa-platform/sa-p-core/session-events.js";
import {VueHammer} from "vue2-hammer";
import * as util from "@/util";
import {store} from "./store/store.js";
import HeaderComponent from "./components/sac-c-sageneral-components/components/sac-c-sageneral-header/sac-c-sageneral-header.vue";
import VendorProfile from "./templates/sac-t-pp-vendorprofilecustside/sac-t-pp-vendorprofilecustside.vue";
import SAF7AppCore from "@sa-platform/sa-p-core/framework7-app-core.vue";

let app;

function patchSessionAxiosHandler() {
  const original = SASession.AxiosSessionHandler;
  SASession.AxiosSessionHandler({
    sessionExpiredCallBack: () => SessionEvents.$emit("user-session-expired", true),
  });

  SASession.AxiosSessionHandler = function(opt) {};
}

function patchHeaderComponent(f7) {
  HeaderComponent.methods.back = () => {
    f7.views.main.router.back();
  };
}

function patchAppCoreRedirect() {
  SAF7AppCore.mounted = () => {};
}

function findRouteByPath(routes, path) {
  return routes.find(r => {
    if (r.path === path) return true;

    if (Array.isArray(r.alias)) {
      return r.alias.includes(path);
    }

    return false;
  });
}

function patchRoutes(routes) {
  const route = findRouteByPath(routes, "/");
  const didChange = route.keepAlive === undefined;
  route.keepAlive = true;

  return didChange;
}

/*
 * A horrible hack to make sure the keepAlive flag does not get removed
 * from customer feed route when user logs in
 */
window.addEventListener("user-session-init", async () => {
  for (let i = 0; i < 10; i++) {
    await util.wait(250);
    if (patchRoutes(window.routes)) {
      return;
    }
  }
});

SAPlatform.startApp({version: "1.0.0", Vue}, (data) => {
  // Let's get routes
  const routes = data.routes;

  // Patch customer feed route with keepAlive flag
  patchRoutes(routes);
  patchSessionAxiosHandler();
  patchAppCoreRedirect();

  /*
   * Here you can define custom routes (Can be modified).
   */
  routes.push({path: "/preview/", component: Page});

  routes.push({path: "/area51/", component: Area51});
  routes.push({path: "/area51/:routePl/", component: Area51});

  routes.push({path: "/area52/", component: Area52});
  routes.push({path: "/area52/:routePl/", component: Area52});

  routes.push({path: "/vendor/:slug", component: VendorProfile, analyticsTitle: "Vendor Profile"});

  if (window.SAPlatform.server.disable) {
    /* Will be executed if backend connection is disabled (Can be modified).  */
    routes.push({path: "/", component: Area51});
  }

  window.routes = routes;

  // Initing VueX
  Vue.use(Vuex);

  // Initing VueHammer
  Vue.use(VueHammer);

  // Init F7 Vue Plugin
  Framework7.use(Framework7Vue);

  // Init App
  app = new Vue({
    el: "#app",
    template: "<app/>",
    pushState: true,
    store,

    // Register App Component
    components: {
      app: App,
    },
  });

  patchHeaderComponent(app.$f7);
  SessionEvents.init(app);

  window.__app = app;

  // Fix for feed losing position if return from gallery
  const _fetchContent = window.SAPlatform.fetch.fetchContent;
  window.SAPlatform.fetch.fetchContent = function(cb) {};
});
